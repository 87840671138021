import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import comments from "data/comments";
import "./CommentsCarousel.scss";

const MAX_VISIBILITY = 3;

const Card = ({ text, image, company, name, stars }) => {
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    return (
        <Box
            className="card"
            width={"100%"}
            height={isNonMinMobileScreen ? "100%" : "auto"}
            minHeight={isNonMinMobileScreen ? "70%" : "16rem"}
            padding={isNonMinMobileScreen ? "2rem" : "15px"}
            paddingTop={isNonMinMobileScreen ? 0 : ""}
            justifyContent={"start"}
            justifyItems={"start"}
            alignContent={isNonMinMobileScreen ? "center" : "start"}
            marginLeft={"auto"}
            marginRight={"auto"}
        >
            <Box
                width={"100%"}
                minHeight={"25%"}
                height={"auto"}
                display={"flex"}
                flexDirection={"row"}
                marginBottom={"18px"}
                // border={"2px solid red"}
            >
                <Box
                    width={isNonMinMobileScreen ? "25%" : "30%"}
                    marginRight={isNonMinMobileScreen ? "0px" : "10px"}
                    height={"100%"}
                    display={"flex"}
                    justifyContent={"start"}
                    alignItems={"center"}
                >
                    <img src={image} alt="profile" width={company === "Room Tour" ? "90px " : "66px"} height={"66px"} />
                </Box>
                <Box
                    width={isNonMinMobileScreen ? "70%" : "98%"}
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    paddingTop={0}
                >
                    <Typography variant="h4" fontWeight={"bold"}>
                        {name}
                    </Typography>
                    <Typography variant="h5" marginTop={"6px"}>
                        {company}
                    </Typography>
                    <img src={stars} alt="stars" width={"91px"} />
                </Box>
            </Box>
            <Box width={isNonMinMobileScreen ? "90%" : "100%"} height={"40%"}>
                <Typography variant="p" fontWeight={"200"}>
                    {text}
                </Typography>
            </Box>
        </Box>
    );
};

const Carousel = ({ children }) => {
    const isNonMinMobileScreen = useMediaQuery("(min-width:500px)");

    const [active, setActive] = useState(2);
    const [userInteracted, setUserInteracted] = useState(false);
    const totalSlides = comments.length;
    useEffect(() => {
        let interval;

        // Ustawienie lub resetowanie interwału
        if (!userInteracted) {
            interval = setInterval(() => {
                setActive((prevActive) => (prevActive + 1) % totalSlides);
            }, 5000); // Automatyczna zmiana co 5 sekund
        } else {
            // Po interakcji użytkownika, odczekaj 5 sekund przed kolejną zmianą slajdu
            interval = setTimeout(() => {
                setUserInteracted(false); // Zresetuj status po 5 sekundach
            }, 5000);
        }

        return () => {
            clearInterval(interval);
            clearTimeout(interval);
        };
    }, [active, userInteracted, totalSlides]);

    return (
        <Box
            className="carousel"
            display={"flex"}
            justifyContent={"center"}
            height={isNonMinMobileScreen ? "23rem" : "16rem"}
        >
            {React.Children.map(children, (child, i) => (
                <Box
                    className="card-container"
                    width={isNonMinMobileScreen ? "100%" : "80%"}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                    display={"flex"}
                    justifyContent={"center"}
                    key={i}
                    style={{
                        "--active": i === active ? 1 : 0,
                        "--offset": (active - i) / 3,
                        "--direction": Math.sign(active - i),
                        "--abs-offset": Math.abs(active - i) / 3,
                        cursor: active === i - 1 || active === i + 1 ? "pointer" : "",
                        opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
                        display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
                    }}
                    onClick={() => {
                        if (active === i + 1) {
                            setActive((i) => i - 1);
                        } else if (active === i - 1) {
                            setActive((i) => i + 1);
                        }
                    }}
                >
                    {child}
                </Box>
            ))}
        </Box>
    );
};

const CommentsCarousel = () => {
    return (
        <Carousel>
            {comments.map((item, index) => (
                <Card name={item.name} text={item.text} image={item.image} company={item.company} stars={item.stars} />
            ))}
        </Carousel>
    );
};

export default CommentsCarousel;
