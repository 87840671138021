import { Box, Typography, useMediaQuery } from "@mui/material";
import logoImg from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";

const FooterComponent = () => {
    const isNonMobileScreen = useMediaQuery("(min-width:500px)");
    const navigate = useNavigate();

    return (
        <Box
            width={"100vw"}
            height={"40vh"}
            sx={{ background: "#1D3557" }}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            marginTop={"5vh"}
        >
            <Box
                width={isNonMobileScreen ? "70vw" : "100%"}
                height={"auto"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                color={"white"}
                gap={"3vh"}
            >
                <Box
                    width={isNonMobileScreen ? "70%" : "100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    padding={"10px"}
                >
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Typography variant="h6" fontWeight={"200"}>
                            BYTE IT RIGHT
                        </Typography>
                        <Typography variant="p" fontWeight={"200"}>
                            kontakt@byteitright.pl
                        </Typography>
                        <Typography variant="p" fontWeight={"200"}>
                            NIP: 5732956775
                        </Typography>
                        <Typography variant="p" fontWeight={"200"}>
                            REGON: 529801325
                        </Typography>
                    </Box>

                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Typography
                            variant="p"
                            fontWeight={"200"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/portfolio", { scrollToStart: true });
                            }}
                        >
                            Portfolio
                        </Typography>
                        <Typography
                            variant="p"
                            fontWeight={"200"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/", { scrollToForm: true });
                            }}
                        >
                            Kontakt
                        </Typography>
                        <Typography
                            variant="p"
                            fontWeight={"200"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/calculator", { scrollToStart: true });
                            }}
                        >
                            Wycena
                        </Typography>
                    </Box>

                    <Box display={"flex"} justifyItems={"center"} alignItems={"center"}>
                        <img src={logoImg} className="white-mask" alt="BYTE-IT-RIGHT" />
                    </Box>
                </Box>
                <Box width={"100%"} borderBottom={"1px solid white"} />
                <Box width={"70%"}>
                    <Typography
                        variant="p"
                        fontWeight={"200"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            navigate("/private-policy", { scrollToStart: true });
                        }}
                    >
                        Polityka prywatności
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default FooterComponent;
